import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import logo3 from "../assets/logo32.png";
import { MenuIcon, XIcon } from '@heroicons/react/outline';
// import { Fade } from 'react-awesome-reveal';
import { useNavigate } from 'react-router-dom';
import Facebook from '../assets/facebook.png';
import insta from '../assets/insta.png';
import linkdin from '../assets/linkdin.png';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [scrollingDown, setScrollingDown] = useState(false); // State for scroll direction
  const [lastScrollTop, setLastScrollTop] = useState(0); // Track the last scroll position
  const [logoSize, setLogoSize] = useState(1.5); // Initially set the logo to 1.5 on large screens
  const [logoMt, setLogoMt] = useState(false); // Initially set the logo to 1.5 on large screens
  const [showTopNavbar, setShowTopNavbar] = useState(true); // Show/hide top navbar when at the top
  const [mainNavbarMarginTop, setMainNavbarMarginTop] = useState('70px'); // Adjust margin-top of the main navbar
  const [screenWidth, setScreenWidth] = useState(window.innerWidth); // Track screen width

  const navigate = useNavigate();

  // Handle the scroll event to detect scroll direction and adjust logo size
  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop;

      // For small screens, we don't want to adjust the margin-top
      if (screenWidth <= 1024) return;

      if (currentScroll === 0) {
        setShowTopNavbar(true); // Show top navbar when at the top
        setLogoSize(1.5); // Make logo larger when at the top
        setLogoMt(true); // Make logo larger and apply margin-top = -40px when at the top
        setMainNavbarMarginTop('70px'); // Set margin-top when at the top
      } else {
        setShowTopNavbar(false); // Hide top navbar when scrolled down
        setLogoMt(false); // Reset logo margin-top when scrolled down
        setLogoSize(1); // Reset logo size when scrolled down
        setMainNavbarMarginTop('0px'); // Remove margin-top when scrolled down
      }

      // Detect scrolling direction to hide top navbar on scroll down
      if (currentScroll > lastScrollTop) {
        setScrollingDown(true);
      } else {
        setScrollingDown(false);
      }

      setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll); // Prevent negative scroll
    };

    // Add event listener for scroll
    window.addEventListener('scroll', handleScroll);

    // Handle initial scroll state on page load (check if it's at the top)
    handleScroll();  // Manually call handleScroll to check scroll position on page load

    return () => window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
  }, [lastScrollTop, screenWidth]);


  // Track screen resize for responsive navbar
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
  }, []);

  // Ensure margin-top is always 0px on small screens
  useEffect(() => {
    if (screenWidth <= 1024) {
      setMainNavbarMarginTop('0px'); // Make sure margin-top is 0 for small screens
    }
  }, [screenWidth]);

  return (
    <div className="text-black fixed w-full z-50 left-0 bg-white">
      {/* Conditionally render Top Navbar based on screen width */}
      {screenWidth > 1024 && (
        <div
          className={`top-navbar ${showTopNavbar ? 'opacity-100' : 'opacity-0'} ${showTopNavbar ? 'pointer-events-auto' : 'pointer-events-none'} absolute w-full transition-all duration-500 ease-in-out`}
          style={{ padding: '30px 0', top: 0, left: 0, }}
        >
          <div
            className="flex justify-end space-x-6 width-1800"
            style={{ margin: 'auto', paddingRight: '50px', position: 'relative' }}
          >
            <ul
              className="flex"
              style={{ gap: '20px', color: 'rgb(57, 57, 60)', listStyle: 'none', padding: 0, margin: 0 }}
            >
              <li>
                <a
                  href="https://www.facebook.com/ReadON.Arabia/?ref=page_internal"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: 'inline-block' }}
                >
                  <img src={Facebook} alt="Facebook" style={{ width: '25px' }} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/readon.arabia/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: 'inline-block' }}
                >
                  <img src={insta} alt="Instagram" style={{ width: '25px' }} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/readon-arabia/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: 'inline-block' }}
                >
                  <img src={linkdin} alt="LinkedIn" style={{ width: '25px' }} />
                </a>
              </li>
            </ul>
            {/* Vertical Line and Text */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  borderLeft: '1px solid #ccc',
                  height: '20px',
                  marginRight: '15px',
                }}
              ></div>
              <a
              href="https://readon.ai/"
              className="items-center flex"
              style={{
                color: '#39393c',
                fontWeight: 800, // Makes the text extra bold
                fontSize: '0.875rem', // Equivalent to text-sm in Tailwind (14px)
                textDecoration: 'none',
              }}
              onMouseEnter={(e) => (e.target.style.color = '#fb8500')}
              onMouseLeave={(e) => (e.target.style.color = '#39393c')}
            >
              ALREADY REGISTERED? LOGIN
            </a>

            </div>
          </div>
        </div>
      )}

      {/* Main Navbar */}
      <nav
        style={{
          width: '100%',
          margin: 'auto',
          flexDirection: 'row',
          marginTop: mainNavbarMarginTop,
          transition: 'margin-top 0.3s ease',
        }}
        className='width-1800 flex justify-between items-center bg-white px-6 md:px-12 py-4'
      >
        {/* <Fade direction='left' damping={0.4} triggerOnce={true}> */}
          <div className="flex items-center space-x-4">
            <div className="h-12 w-28"
              style={{
                transform: `scale(${screenWidth > 1024 ? logoSize : 1})`,
                transition: 'transform 0.3s ease-in-out',
                transformOrigin: 'bottom',
                marginTop: logoMt ? '-10px' : '0px', // Corrected inline style for marginTop
                marginLeft: screenWidth >= 1024 ? '43px' : '0px',
              }}
            >
              <img src={logo3} alt="ReadON Logo" className="h-full w-full object-contain" />
            </div>

          </div>
        {/* </Fade> */}

        {/* Desktop Navbar Links (Visible only for screenWidth > 1024) */}
        {screenWidth > 1024 && (
          // <Fade direction='top' damping={0.8} triggerOnce={true}>
            <div
              className={`hidden lg:flex flex-grow justify-center max-w-screen-xl w-full font16-bold ${scrollingDown ? 'top-navbar-hide' : ''}`}
              style={{ maxWidth: '1600px', fontSize: '18px'}}
            >
              <div className="flex space-x-6 justify-center w-full max-w-screen-xl" style={{ color: 'rgb(57, 57, 60)' }}>
                <Link to="about" smooth={true} duration={500} className="cursor-pointer hover:text-gray-600">About Us</Link>
                <Link to="whychooseus" smooth={true} duration={500} className="cursor-pointer hover:text-gray-600">Why Choose Us?</Link>
                <Link to="benefits" smooth={true} duration={500} className="cursor-pointer hover:text-gray-600">Benefits</Link>
                <Link to="howdoes" smooth={true} duration={500} className="cursor-pointer hover:text-gray-600">How Does It Work?</Link>
                <Link to="testimonial" smooth={true} duration={500} className="cursor-pointer hover:text-gray-600">Testimonials</Link>
                <Link to="footer" smooth={true} duration={500} className="cursor-pointer hover:text-gray-600">Contact Us</Link>
              </div>
            </div>
          // </Fade>
        )}

        {/* Language Selector (Indian Flag) and Sign In Button */}
        {/* <Fade direction='right' damping={0.4} triggerOnce={true}> */}
          <div className="hidden lg:flex items-center space-x-3">
            <button className="bg-[#FB8500] font18-light text-white px-3 py-1 rounded hover:bg-[#dc933f] transition focus:outline-none" style={{ width: '200px' }} onClick={() => navigate('/form')}>
              Start an Assessment
            </button>
          </div>
        {/* </Fade> */}

        {/* Mobile Navbar Menu Button */}
        <button
          className="lg:hidden"
          onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
        >
          {isMobileMenuOpen ? <XIcon className="h-6 w-6" /> : <MenuIcon className="h-6 w-6" />}
        </button>

        {/* Mobile Navbar Menu */}
        {isMobileMenuOpen && (
          <div className="absolute top-16 left-0 w-full bg-white z-40 lg:hidden flex flex-col items-center space-y-4 py-4">
            <Link to="about" smooth={true} duration={500} onClick={() => setMobileMenuOpen(false)} className="cursor-pointer hover:text-gray-600">About Us</Link>
            <Link to="whychooseus" smooth={true} duration={500} onClick={() => setMobileMenuOpen(false)} className="cursor-pointer hover:text-gray-600">Why Choose Us?</Link>
            <Link to="benefits" smooth={true} duration={500} onClick={() => setMobileMenuOpen(false)} className="cursor-pointer hover:text-gray-600">Benefits</Link>
            <Link to="howdoes" smooth={true} duration={500} onClick={() => setMobileMenuOpen(false)} className="cursor-pointer hover:text-gray-600">How Does It Work?</Link>
            <Link to="testimonial" smooth={true} duration={500} onClick={() => setMobileMenuOpen(false)} className="cursor-pointer hover:text-gray-600">Testimonials</Link>
            <Link to="footer" smooth={true} duration={500} onClick={() => setMobileMenuOpen(false)} className="cursor-pointer hover:text-gray-600">Contact Us</Link>

            <button
              className="bg-[#FB8500] text-white rounded hover:bg-[#dc933f] transition focus:outline-none px-2 py-1"
              onClick={() => window.open('https://readon.ai/', '_blank')}
            >
              Sign In
            </button>

          </div>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
